.login-left{
 width: 50vw;
 height: 100vh;
 background-image: url("../image/yellow.png");
}


.login-right{
    width: 50vw;
    height: 100vh;
}

.yellow-login{
    height:100vh;
    width:50vw;

}

.cuate-login{
    z-index: 0;
    margin: -40vh 10vw;
    height:60vh
}
.grayCurve{
    height: 60vh;
    margin-bottom: -15vh;
    z-index: 1;
}
.login-btn{
    height: 4vh;
    width: 10vw;
    border: none;
    background: #F9D422;
    margin: 6vh 0 0 10vw;

}

.login-form{
    margin: 20vh 10vw;  
}

.login_text {
    color: black;
    text-align: center;
    font-size: 3rem;
    margin: 5% 0 3% 0;
}

.username_div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    width: 30vw;
    height: 70vh;
    margin: 0 auto;
}

.otpVerifyModal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #F3F7FD;
    width: 30vw;
    height: 70vh;
    margin: 15vh 35vw;
    border-radius: 20px;
    padding: 0 2rem;
}

.otpForm{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5vw;
    width: 100%;
    height: 100%;

}

.otpInput{

    border-radius: 10px;
    border-style: none;
    font-size: 1.5rem;
    font-family: 'Lusitana', sans-serif;
    padding: 1.8vw;
}

.otpInputSeprater{
    font-size: 2.5vw;
    margin: 1vw;
    font-family: 'Lusitana', sans-serif;
}

.verify_button{
    display: block;
    margin: 0 auto 0 auto;
    width: 20vw;
    height: 5vw;
    background-color: white;
    border-style: none;
    font-size: 1.5rem;
    font-family: 'Lusitana', sans-serif;
    border-radius: 10px;
}

.verify_button:hover{
    cursor: pointer;
    background-color: black;
    color: white;
}

.otpForm label{
    font-size: 2.2vw;
    font-weight: 500;
    font-family: 'Lusitana', sans-serif;
    color: #727272;
}


