* {
    margin: 0;
    padding: 0;
}

.feature-text-div {
    width: 14vw;
    padding: 0px 25px 20px 25px;
}

.feature-text-div > h3 {
    font-size: 22px;
}

