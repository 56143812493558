.purple-div {
    padding: 8vw 30vw 2vw 30vw;
}

.search-div {
    border-radius: 10px;

}

.search-div > input[type="text"] {
    border: none;
    outline: none;
    width: 100%;
    border-radius: 10px;
    color: black;
}

.search-div > input[type="text"]::placeholder {
    color: black;
    opacity: 1;
}

.purple-text-area {
    margin-top: 8vh;
}

.purple-text-area > p {
    margin-top: 3vh;
}

.phone-section > img {
    height: 55vh;
    width: auto;
    margin-top: 2vh;
}

.searchlist{
    position: relative;
    display: flex;
    align-items: center;
    text-align: left;
    text-decoration: none;
    font-size: 1.2vw;
    color: black;
    height: 3vw;
    padding: 0 1vw;

}

.searchlist:hover{
    text-decoration: none;
    color: rgb(88, 88, 88);
    background-color: #f2f2f2;
}

.viewallstudio{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    text-decoration: none;
    font-size: 1.2vw;
    color: black;
    padding: 0 1vw;
}

.viewallstudio:hover{
    text-decoration: none;
    color: #F9D422;
    
}