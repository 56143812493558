.navbar-m {
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    width: 90vw;
    justify-content: space-between;
    align-items: center;
    padding: 0 5vw;
    height: 8vh;
    z-index: 1;
    box-shadow: 1px 12px 13px rgb(0,0,0,0.25);;
}

.sections {
    /* background-color: green; */
    width: 10vw;
}

.section-area {
    list-style-type: none;
    display: flex;
    padding: 0;
    justify-content: center;
    gap: 2vw;
    /* background-color: pink; */   
}

.button-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1vw;
}

.notification-btn, .create-profile-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4vh;
    border: none;
    outline: none;
}

.create-profile-btn {
    width: 8vw;
    border-radius: 10px;
    
}

.urls {
    text-decoration: none;
    color: black;
}

.loginbutton{
    background-color: #F9D422;
    border-radius: 10px;
    border: none;
    outline: none;
    padding: 0.5vw 1vw;
    font-size: 1vw;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
}
.profileBtn{
    border-radius: 0.5vw;
    border: none;
    outline: none;
    padding: 0.4vw 0.1vw;
    font-size: 1vw;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
}
.logoutBtn{
    border-radius: 0.5vw;
    border: none;
    outline: none;
    padding: 0.3vw 0.1vw;
    font-size: 1vw;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
}
