

.content {
    padding: 8.65vmax 20px 0px 20px;
}

.container {
    margin: 0 auto;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.col-md-6 {
    max-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.heading {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

form {
    margin-bottom: 5%;
    width: 100%;
}

.form-group {
    margin-bottom: 15px;
    width: 100%;
}

input,
textarea {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

input[type="submit"] {
    background-color: #F9D422;
    color: #fff;
    cursor: pointer;
    border-radius: 8px;
}

#form-message-success {
    color: #28a745;
    margin-top: 10px;
}

#form-message-warning {
    color: #dc3545;
    margin-top: 10px;
}
