* {
    margin: 0;
    padding: 0;
}

.feature-title {
    font-size: 2.5rem; 
    text-align: right;
    padding-bottom: 4vh;
}

.feature-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15vw;
}

.features-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.grid-box{

    display: grid;
    
    width: fit-content;
    padding: 2vh 0;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(2, auto);
    gap: 10vh 8vw;
}

.feature-phone-image {
    margin-top: 10vh;
}