.card {
    display: flex;
    flex-direction: column;
    padding-bottom: 2vh;
    border-radius: 10px;
    box-shadow: 7px 8px 15px 0px rgba(0,0,0,0.17);
    height: auto;
    
}

.image-section {
    width: 100%;
    height: 10vw;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.image-section > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.details {
    display: flex;
    height: 9vh;
    padding: 0 10px;
    font-size: 13px;
    flex-direction: column;
    justify-content: center;
    align-items: left;
}

.studio-name {
    margin: 15px 0;
    font-size: 16px;
}

.mPin {
    margin: 0 5px;
}