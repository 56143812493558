.st-banner {
    background: center / cover no-repeat url("../image/banner.svg");
    opacity: 0.5;
    height: 50vh;
    padding: 0 25vw;
    margin-top: 8vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.st-banner-1{
    position: absolute;
    top: 8vh;
    height: 50vh;
    display:flex;
    padding: 2vw 25vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.st-banner > span {
    text-align: center;
    font-size: 1.2rem;
}

.st-bn-title {
    font-size: 3rem !important;
    margin-bottom: 5vh;
}

.about-us-sec {
    display: flex;
    justify-content: space-between;
    gap: 0 8vw;
    align-items: flex-end;
    height: fit-content;
    padding: 0 4vw;
    margin: 15vh 0;
}

.about-text {
    display: flex;
    flex-direction: column;
    width: 55vw;
    font-size: 2px;

}

.ab-txt {
    font-size: 2.3rem !important;
    margin-bottom: 3vh;
}

.our-service {
    margin-bottom: 15vh;
}

.service-title {
    display: block;
    text-align: center;
    font-size: 2.3rem;
    margin-bottom: 5vh;
}

.service-div {
    display: flex;
    justify-content: space-between;
    padding: 0 25vw;
}

.ser-d1 {
    width: 10vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 20px;
    padding: 3vh 1vw 6vh 1vw;
    box-shadow: 12px 13px 21px 0px rgba(0,0,0,0.14);
}

.purple-circle{
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    background-color: rgba(48, 49, 157, 0.44);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3vh;
}

.post-job {
    margin-bottom: 15vh;
}

.post-head {
    font-size: 2.3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2vw;
    margin-bottom: 3vh;
}

.apply {
    width: 7vw;
    height: 3.5vh;
    border: none;
    outline: none;
    font-size: 16px;
    background-color: #F9D422;
    color: black;
    border-radius: 7px;
}

.post-job1 {
    width: 13vw;
    padding-bottom: 2vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    border-radius: 15px;
    box-shadow: 7px 8px 15px 0px rgba(0,0,0,0.17);
}

.post-job1 > img {
    width: 100%;
    height: 25vh;
    object-fit: cover;
    margin-bottom: .5vmax;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.post-job1 > span {
    padding: 0 0.5vmax;
    font-size: 18px;
    font-weight: 600;
}

.position {
    font-size: 13px !important;
    min-height: 2.2vmax;
    padding: 0 0.5vmax;
    max-height: 2.5vmax;
    width: 12vmax;
    overflow: hidden;
}

.post-job-divs {
    padding: 2vh 2vw;
    display: flex;
    gap: 0 5vw;
}

.purple-area-std {
    /* width: 100vw; */
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(48, 49, 157, 0.44);
    margin-bottom: 15vh;
    max-width: 100vw;
}

.purple-area-std > p {
    padding: 0 15vw;
    font-size: 25px;
    text-align: center;
}

footer {
    background-color: rgba(48, 49, 157, 0.44);
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3vh 0; 
}