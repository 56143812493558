.right-bottom {
    margin-top: 5vh;
}

.r-b-top {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 2vh;
    margin-top: 6vh;

}

.picture-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 94%;
    padding: 0 1%;
    font-size: 20px;
}

.r-b-data {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(7.2vw, 1vw));
    gap: 2vh 2.5vw;   
}
.r-b-data2 {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(5vw, 10vw));
    gap: 2vh 13vw;   
}

.r-b-data > img {
    width: 8vw;
    height: 6vw;
}

.r-b-data > audio {
    
    height: 2.5vw;
    width: 8vw;
}

.r-b-data > video {
    width: 8vw;
    height: 6vw;
}

.grey-text {
    color: #747474;
    font-size: 20px;
}

.empty-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}